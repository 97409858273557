import * as React from 'react';
import { Grid, Container, Box, Typography } from '@mui/material';

import productphoto from "../../assets/mockup.png";

function Product(props) {
    return (
        <Box
            sx={{
                bgcolor: 'white',
            }}
        >
            <Container
                sx={{
                    pt: 10,
                    pb: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <img
                            src={productphoto}
                            alt="productphoto"
                            style={{ maxWidth: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography color="primary" align="left" variant="h2" sx={{ mb: 1 }}>
                            With our platform, you'll easily be able to do:
                        </Typography>
                        <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                            <Box component="li" sx={{ py: 1, display: 'inline-flex' }}>
                                <Typography color="secondary" align="left" variant="h1" sx={{ mr: 2 }}>/</Typography>
                                <Typography color="primary" align="left" variant="body1">
                                    Scouting - Monitor crops and fields in real-time, providing valuable insights into soil moisture content, weed control, and crop estimates
                                </Typography>
                            </Box>
                            <Box component="li" sx={{ py: 1, display: 'inline-flex' }}>
                                <Typography color="secondary" align="left" variant="h1" sx={{ mr: 2 }}>/</Typography>
                                <Typography color="primary" align="left" variant="body1">
                                    Harvesting - Record and monitor the progress and efficiency of each labourer individually, in real-time as well as a global overview of the progress of your harvest across all teams
                                </Typography>
                            </Box>
                            <Box component="li" sx={{ py: 1, display: 'inline-flex' }}>
                                <Typography color="secondary" align="left" variant="h1" sx={{ mr: 2 }}>/</Typography>
                                <Typography color="primary" align="left" variant="body1">
                                    Time and attendance - Let people clock in and out on the mobile app, while also providing you with the location where it took place
                                </Typography>
                            </Box>
                            <Box component="li" sx={{ py: 1, display: 'inline-flex' }}>
                                <Typography color="secondary" align="left" variant="h1" sx={{ mr: 2 }}>/</Typography>
                                <Typography color="primary" align="left" variant="body1">
                                    Financial - Calculate wages automatically according to time and attendance and harvesting records, including customised bonuses and incentives
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Product;