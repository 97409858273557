import * as React from 'react';
import ProductHero from '../../components/Hero';
import GettingStarted from './gettingstarted';
import Pricing from './pricing';
import AboutUs from './aboutus';
import Product from './product';
import Intro from './intro';

function Home() {
    return (
        <React.Fragment>
            <ProductHero />
            <section id="overview">
                <Intro />
            </section>
            <section id="product">
                <Product />
            </section>
            <section id="gettingstarted">
                <GettingStarted />
            </section>
            <section id="pricing">
                <Pricing />
            </section>
            <section id="aboutus">
                <AboutUs />
            </section>
        </React.Fragment>
    );
}

export default Home;