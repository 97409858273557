import * as React from 'react';
import { Container, Box, Typography } from '@mui/material';

function Intro(props) {
    return (
        <Box
            sx={{
                backgroundColor: 'primary.main',
                position: 'relative',
                display: 'flex',
            }}
        >
            <Container
                sx={{
                    pb: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '80%'
                    }}
                >
                    <Typography sx={{ marginTop: 10 }} color="primary.contrastText" align="center" variant="h1" marked="center">
                        Integrating Tech Solutions for Agriculture
                    </Typography>
                    <Typography sx={{ marginTop: 5 }} color="primary.contrastText" align="center" variant="body1" marked="center">
                        Are you <Typography sx={{ fontWeight: 700, display: 'inline' }} color="primary.contrastText" align="center" variant="body1" marked="center">frustrated</Typography> with your current agriculture management solutions?
                    </Typography>
                    <Typography sx={{ marginTop: 2, fontWeight: 700 }} color="primary.contrastText" align="center" variant="body1" marked="center">
                        Do you find yourself using multiple systems to manage different aspects of your operation, only to find that the data is siloed, difficult to consolidate and often needs to be duplicated across different systems?
                    </Typography>
                    <Typography sx={{ marginTop: 2 }} color="primary.contrastText" align="center" variant="body1" marked="center">
                        Have you ever experienced the feeling that your current solutions are <Typography sx={{ fontWeight: 700, display: 'inline' }} color="inherit" align="center" variant="body1" marked="center">outdated</Typography> and should be easier to use and more applicable to the day and age we are currently in?
                    </Typography>
                    <Typography sx={{ marginTop: 5 }} color="primary.contrastText" align="center" variant="body1" marked="center">
                        We strive to offer a comprehensive solution that integrates seamlessly with your existing systems to provide a single source of truth for your operation. Say goodbye to frustration and hello to a more efficient and productive agriculture business.
                    </Typography>
                </Container>
            </Container>
        </Box>
    );
}

export default Intro;