import * as React from 'react';
import { Typography, Container, Link, Grid, Box, SvgIcon } from '@mui/material';

import { ReactComponent as GoogleIcon } from '../../assets/googleplaystore.svg';
import { ReactComponent as AppStoreIcon } from '../../assets/appstore.svg';
import { ReactComponent as WebStoreIcon } from '../../assets/webapp.svg';
// import { ReactComponent as AppGalleryIcon } from '../../assets/appgallery.svg';

import AllesbesteLogo from "../../assets/allesbeste_logo.png";

function Copyright() {
    return (
        <Container sx={{ textAlign: 'center' }}>
            {'© '}
            <Link color="inherit" target="_blank" href="https://www.allesbeste.com/">
                AH Ernst & Seuns (Pty) Ltd.
            </Link>{' '}
            All rights reserved.
            {' '}
            {new Date().getFullYear()}
        </Container>
    );
}

export default function AppFooter() {
    return (
        <Typography
            component="footer"
            sx={{ display: 'flex', bgcolor: 'primary.main', color: 'white' }}
        >
            <Container sx={{ my: 5, display: 'flex' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" marked="left" gutterBottom color="primary.contrastText">
                            Available on
                        </Typography>
                        <Link href="https://apps.apple.com/za/app/root-system/id1510530054" target="_blank" color="primary.contrastText" sx={{ display: 'inline-block', mt: 1 }}>
                            <SvgIcon component={AppStoreIcon} inheritViewBox color='white' sx={{ fontSize: 200, height: 'auto' }} />
                        </Link>
                        <Link href="https://play.google.com/store/apps/details?id=com.allesbeste.bsmobile" target="_blank" color="primary.contrastText" sx={{ display: 'inline-block', mt: 1 }}>
                            <SvgIcon component={GoogleIcon} inheritViewBox color='white' sx={{ fontSize: 200, height: 'auto' }} />
                        </Link>
                        {/* <Link href="https://appgallery.huawei.com/app/C107765793" target="_blank" color="primary.contrastText" sx={{ display: 'inline-block', mt: 1 }}>
                            <SvgIcon component={AppGalleryIcon} inheritViewBox color='white' sx={{ fontSize: 200, height: 'auto' }} />
                        </Link> */}
                        <Link href="https://app.rootsolutions.tech/" target="_blank" color="primary.contrastText" sx={{ display: 'inline-block', mt: 1 }}>
                            <SvgIcon component={WebStoreIcon} inheritViewBox color='white' sx={{ fontSize: 200, height: 'auto' }} />
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" marked="left" gutterBottom color="primary.contrastText">
                            Support
                        </Typography>
                        <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Link href="/termsofuse" variant="body2" color="primary.contrastText">Terms of use</Link>
                            </Box>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Link href="/privacypolicy" variant="body2" color="primary.contrastText">Privacy policy</Link>
                            </Box>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Link href="https://docs.rootsolutions.tech/" target="_blank" variant="body2" color="primary.contrastText" underline="none">Documentation</Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" marked="left" gutterBottom color="primary.contrastText">
                            Need help?
                        </Typography>
                        <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
                            <Box component="li" sx={{ py: 0.5 }}>
                                <Link variant="body2" color="primary.contrastText"
                                    to='#'
                                    onClick={(e) => {
                                        window.location.href = `mailto:info@rootsolutions.tech`;
                                        e.preventDefault();
                                    }}
                                >
                                    Email us
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            mb: 3,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                        }}>
                            <Typography variant="body2" marked="left" gutterBottom color="primary.contrastText" sx={{ mr: 2 }}>
                                Powered by
                            </Typography>
                            <img
                                src={AllesbesteLogo}
                                alt="Allesbeste"
                                height="50"
                            />
                        </Box>
                        <Copyright />
                    </Grid>
                </Grid>
            </Container>
        </Typography>
    );
}