import * as React from 'react';
import { Button } from '@mui/material';
import ProductHeroLayout from './herolayout';
import backgroundImage from "../../assets/background_full.jpg";

export default function ProductHero() {
    return (
        <ProductHeroLayout
            sxBackground={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundColor: '#a4b14c',
                backgroundPosition: 'center',
            }}
        >
            <img
                style={{ display: 'none' }}
                src={backgroundImage}
                alt="increase priority"
            />
            <Button
                color="secondary"
                variant="contained"
                size="large"
                component="a"
                href="/#gettingstarted"
                sx={{ marginTop: 10, minWidth: 200, display: { xs: 'none', sm: 'flex' } }}
            >
                Get started
            </Button>
        </ProductHeroLayout>
    );
}