import { Routes, BrowserRouter, Route } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Home from "./views/Home";
import MainLayout from "./hoc/layout/withnav";
import Terms from "./views/Terms";
import NotFound from "./views/NotFound";
import PricingPage from "./views/Pricing";
import PrivacyPolicy from "./views/PrivacyPolicy";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <MainLayout>
                <Home />
              </MainLayout>
            }
          />
          <Route
            path="/termsofuse"
            element={
              <MainLayout>
                <Terms />
              </MainLayout>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <MainLayout>
                <PrivacyPolicy />
              </MainLayout>
            }
          />
          <Route
            path="/pricing"
            element={
              <MainLayout>
                <PricingPage />
              </MainLayout>
            }
          />

          <Route
            path="/not-found"
            element={
              <MainLayout>
                <NotFound />
              </MainLayout>
            }
          />
          <Route
            path="*"
            element={
              <MainLayout>
                <NotFound />
              </MainLayout>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
