import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Box, Typography } from '@mui/material';

import NotFoundImage from "../../assets/error404.png";

const LayoutRoot = styled('section')(({ theme }) => ({
    color: theme.palette.common.white,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        height: '100vh',
        minHeight: 500,
        maxHeight: 1300,
    },
}));

const Background = styled(Box)({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 50,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
});

function NotFound() {
    return (
        <React.Fragment>
            <LayoutRoot>
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Background
                        sx={{
                            backgroundImage: `url(${NotFoundImage})`,
                            backgroundPosition: 'top',
                        }}
                    />
                    <Typography color="primary" align="center" variant="h1" marked="center">
                        404
                    </Typography>
                    <Typography color="primary" align="center" variant="h4" marked="center">
                        Not Found
                    </Typography>
                </Container>
            </LayoutRoot>
        </React.Fragment>
    );
}

export default NotFound;