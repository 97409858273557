import * as React from 'react';
import { Grid, Container, Box, Typography, SvgIcon } from '@mui/material';
import { ReactComponent as VisionIcon } from '../../assets/vision.svg';
import { ReactComponent as MissionIcon } from '../../assets/mission.svg';

function AboutUs(props) {
    return (
        <React.Fragment>
            <Box
                sx={{
                    bgcolor: 'secondary.main',
                }}
            >
                <Container
                    sx={{
                        pt: 10,
                        pb: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12} sx={{ mb: 8 }}>
                            <Typography color="primary.contrastText" align="center" variant="body1" marked="center">
                                <Typography color="primary.contrastText" align="center" variant="h2" marked="center" sx={{ display: 'inline', mr: 1 }}>
                                    As farmers ourselves,
                                </Typography>
                                we understand the challenges of the industry firsthand. That's why we created our agriculture management software - to address the pain points we faced and make our own operations more efficient and successful. We understand what farmers need and what will make their lives easier, and we built our platform with these needs in mind.
                                Because we created our software for ourselves, we've had the unique opportunity to test and refine it in real-world scenarios, ensuring that it's practical, effective and user-friendly. Our platform is constantly evolving as we continue to identify new ways to innovate, so you can continuously benefit from our passion for technology and be confident that you're getting the best solution available.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Container sx={{ display: 'flex' }}>
                                <Box sx={{ mt: 10, display: { flex: 0 } }}>
                                    <SvgIcon component={VisionIcon} inheritViewBox color='primary.contrastText' sx={{ fontSize: 200, height: 'auto', color: "white" }} />
                                </Box>
                                <Box sx={{ display: { flex: 1 } }}>
                                    <Typography color="primary.contrastText" align="left" variant="h1" marked="center" sx={{ mb: 1, fontSize: 60 }}>
                                        Our vision
                                    </Typography>
                                    <Typography color="primary.contrastText" align="left" variant="body2" marked="center" sx={{ ml: 5 }}>
                                        Our vision is to be the leading provider of integrated and innovative agricultural management solutions, empowering farmers worldwide with the tools and insights they need to thrive in an ever-evolving industry. We envision a future where technology seamlessly integrates with agriculture, and all technologies seamlessly integrates with each other, resulting in more efficient and sustainable practices.
                                    </Typography>
                                </Box>
                            </Container>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 5 }}>
                            <Container sx={{ display: 'flex' }}>
                                <Box sx={{ display: { flex: 1 } }}>
                                    <Typography color="primary.contrastText" align="left" variant="h1" marked="center" sx={{ mb: 1 }}>
                                        Our mission
                                    </Typography>
                                    <Typography color="primary.contrastText" align="left" variant="body2" marked="center">
                                        Our mission is to revolutionize agriculture management through the development of affordable and user-friendly software. By collaborating with farmers and leveraging cutting-edge technology, we strive to provide integrated solutions that streamline operations and drive efficiency, ultimately enhancing the success and sustainability of the agriculture industry.
                                    </Typography>
                                </Box>
                                <Box sx={{ display: { flex: 0 }, ml: 5 }}>
                                    <SvgIcon component={MissionIcon} inheritViewBox color='white' sx={{ fontSize: 100, height: 'auto', color: "white" }} />
                                </Box>
                            </Container>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </React.Fragment >
    );
}

export default AboutUs;