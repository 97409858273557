import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function PricingTable() {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Module</TableCell>
                        <TableCell>Free tier usage</TableCell>
                        <TableCell align="right">Cost per record  <br />(after free tier usage)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        key="1"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            Time and attendance
                        </TableCell>
                        <TableCell>
                            300 records per month
                        </TableCell>
                        <TableCell align="right">
                            R 0.04
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="1"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            Harvesting
                        </TableCell>
                        <TableCell>
                            200 records per month
                        </TableCell>
                        <TableCell align="right">
                            R 0.10
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="1"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            Notification subcriptions
                        </TableCell>
                        <TableCell>
                            20 emails per month
                        </TableCell>
                        <TableCell align="right">
                            R 0.25
                        </TableCell>
                    </TableRow>
                    <TableRow
                        key="1"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            Activities/Instructions
                            <br />
                            (Scouting, production instructions, etc.)
                        </TableCell>
                        <TableCell>
                            200 records per month
                        </TableCell>
                        <TableCell align="right">
                            R 0.08
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PricingTable;