import React from "react";

import PropTypes from "prop-types";
import ResponsiveAppBar from "../../../components/AppBar";
import AppFooter from "../../../components/AppFooter";

const MainLayout = (props) => {
    const { children } = props;

    return (
        <div>
            <ResponsiveAppBar />
            {children}
            <AppFooter />
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node,
};

export default MainLayout;
