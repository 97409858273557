import * as React from 'react';
import { Container } from '@mui/material';

import ReactMarkdown from 'react-markdown';
import terms from "./terms.md";

function Terms() {
    const [markdown, setMarkdown] = React.useState();

    React.useEffect(() => {
        fetch(terms).then((response) => response.text()).then((text) => {
            setMarkdown(text);
        })
    }, []);

    return (
        <React.Fragment>
            <Container
                sx={{
                    pt: 10,
                    pb: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Container
                    sx={{
                        alignItems: 'left',
                    }}
                >
                    <ReactMarkdown>{markdown}</ReactMarkdown>
                </Container>
            </Container>
        </React.Fragment>
    );
}

export default Terms;