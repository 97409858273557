import * as React from 'react';
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Drawer, AppBar, Container, Box, Toolbar, IconButton, Button, List, ListItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import logo from "../../assets/logo_wit_1280x500.png";
import { OpenInNew } from '@mui/icons-material';

const CustomRouterLink = React.forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <NavLink {...props} />
    </div>
));
const CustomHashRouterLink = React.forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <HashLink {...props} />
    </div>
));

const pages = [
    {
        title: "Home",
        href: "/#overview",
        component: CustomHashRouterLink
    },
    {
        title: "Product",
        href: "/#product",
        component: CustomHashRouterLink
    },
    {
        title: "Getting started",
        href: "/#gettingstarted",
        component: CustomHashRouterLink
    },
    {
        title: "Pricing",
        href: "/#pricing",
        component: CustomHashRouterLink
    },
    {
        title: "About us",
        href: "/#aboutus",
        component: CustomHashRouterLink
    },
    {
        title: "Documentation",
        endIcon: {
            endIcon: <OpenInNew />
        },
        newpage: true,
        href: "https://docs.rootsolutions.tech/",
        component: CustomRouterLink
    },
];

function ResponsiveAppBar() {
    const [open, setOpen] = React.useState(false);

    const handleSidebarOpen = () => {
        setOpen(true);
    };

    const handleSidebarClose = () => {
        setOpen(false);
    };

    return (
        <AppBar position="fixed">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }} >
                        <img
                            src={logo}
                            alt="root"
                            height="30"
                        />
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            onClick={handleSidebarOpen}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="left"
                            onClose={handleSidebarClose}
                            open={open}
                            variant="temporary"
                        >
                            <List>
                                {pages.map((page) => (
                                    <ListItem key={page.title}>
                                        <Button
                                            variant="navigation"
                                            component={page.component}
                                            to={page.href}
                                            disabled={!page.href ? true : false}
                                            {...page.endIcon}
                                        >
                                            {page.title}
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>

                        </Drawer>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, mr: 1 }} >
                        <img
                            src={logo}
                            alt="root"
                            height="30"
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, flexDirection: "row-reverse" }}>
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page.title}
                                    variant="navigation"
                                    component={page.component}
                                    to={page.href}
                                    disabled={!page.href ? true : false}
                                    target={page.newpage ? "_blank" : null}
                                    sx={{ my: 2, color: 'white', display: 'inline-flex' }}
                                    {...page.endIcon}
                                >
                                    {page.title}
                                </Button>
                            ))}
                        </Box>
                    </Box>

                    <Box sx={{ flexGrow: 0, ml: 2 }}>
                        <Button
                            color="secondary"
                            variant="contained"
                            component={CustomRouterLink}
                            target="_blank"
                            to={"https://app.rootsolutions.tech/"}
                            endIcon={<OpenInNew />}
                        >
                            Web portal
                        </Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;