import * as React from 'react';
import { Card, CardContent, Chip, Container, Grid, Typography } from '@mui/material';
import PricingTable from './pricingtable';

function PricingPage() {
    return (
        <React.Fragment>
            <Container
                sx={{
                    pt: 15,
                    pb: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography color="primary" align="center" variant="h1" marked="center" sx={{ mb: 1 }}>
                    Pricing
                </Typography>
                <Typography color="primary" align="left" variant="body1" marked="center">
                    Our pricing is on a Use Based structure to minimise your expenditure.
                </Typography>
                <Grid container spacing={4} sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: 1
                }}>
                    <Grid item xs={12} sm={3}>
                        <Card sx={{ height: "100%" }}>
                            <CardContent>
                                <Typography color="primary" align="center" variant="h6" marked="center" sx={{ mt: 2, mb: 3 }}>
                                    Enrollment
                                </Typography>
                                <Typography color="primary" align="center" variant="body2" marked="center">
                                    We will help you to get started for free!
                                </Typography>
                                <Typography color="primary" align="left" variant="body2" marked="center" sx={{ fontSize: 12, mt: 1 }}>
                                    * If we need to travel, only travel costs will be incurred.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card sx={{ height: "100%" }}>
                            <CardContent>
                                <Typography color="primary" align="center" variant="h6" marked="center" sx={{ mt: 2, mb: 3 }}>
                                    Training
                                </Typography>
                                <Typography color="primary" align="center" variant="body2" marked="center">
                                    Additional training can be requested and will be charged on an hour-to-hour bases.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card sx={{ height: "100%" }}>
                            <CardContent>
                                <Typography color="primary" align="center" variant="h6" marked="center" sx={{ mt: 2, mb: 3 }}>
                                    Technical Support
                                </Typography>
                                <Typography color="primary" align="center" variant="body2" marked="center">
                                    Technical Support may be billed hourly, based on the level of expertise needed.
                                </Typography>
                                <Typography color="primary" align="left" variant="body2" marked="center" sx={{ fontSize: 12, mt: 1 }}>
                                    * Support needed for a system fault will not be charged.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card sx={{ height: "100%" }}>
                            <CardContent>
                                <Typography color="primary" align="center" variant="h6" marked="center" sx={{ mt: 2, mb: 3 }}>
                                    Specialised Hardware
                                </Typography>
                                <Typography color="primary" align="center" variant="body2" marked="center">
                                    Pricing for our specialised hardware can be requested.
                                </Typography>
                                <Typography color="primary" align="left" variant="body2" marked="center" sx={{ fontSize: 12, mt: 1 }}>
                                    * Don't worry, it's cheaper than you'd think
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <Typography color="primary" align="left" variant="h6" marked="center" sx={{ mt: 2, mb: 2 }}>
                            Usage pricing per company
                        </Typography>
                        <PricingTable />
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <Typography color="primary" align="left" variant="h6" marked="center" sx={{ mt: 2 }}>
                            Scenarios
                        </Typography>
                        <Card sx={{ my: 3 }}>
                            <CardContent>
                                <Typography color="primary" align="left" variant="h2">
                                    Scenario 1
                                </Typography>
                                <Typography color="primary" align="left" variant="body1" sx={{ my: 2 }}>
                                    You have 50 labourers that clock in and out with the mobile application every day. You also create "approved overtime" records for 15 people for the one day that they worked overtime during the month.
                                    You want to stay on top of your attendance, and thus configured to receive a daily absentee report during the week in addition to a weekly attendance report.
                                </Typography>
                                <Container sx={{ my: 2 }}>
                                    <Typography color="primary" align="left" variant="h4" sx={{ mb: 1 }}>
                                        Time and attendance
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="50 labourers" /> x <Chip label="20 work days" /> x <Chip label="2 clock records per day" /> = <Chip label="2 000 records" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="15 labourers" /> x <Chip label="1 overtime record" /> = <Chip label="15 records" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="2 015 records" /> - <Chip label="300 free tier records per month" /> x <Chip label="R 0.04 per record" /> = <Chip variant='outlined' label="R 68.60 per month" />
                                    </Typography>
                                </Container>
                                <Container sx={{ my: 2 }}>
                                    <Typography color="primary" align="left" variant="h4" sx={{ mb: 1 }}>
                                        Notification subscriptions
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="1 Absentee report" /> x <Chip label="1 recipient" /> x <Chip label="20 week days" /> = <Chip label="20 emails" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="1 Attendance report" /> x <Chip label="1 recipient" /> x <Chip label="4 weeks" /> = <Chip label="4 emails" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="24 emails" /> - <Chip label="20 free tier emails per month" /> x <Chip label="R 0.25 per email" /> = <Chip variant='outlined' label="R 1.00 per month" />
                                    </Typography>
                                </Container>
                                <Container sx={{ my: 2 }}>
                                    <Typography color="primary" align="left" variant="h4" sx={{ mb: 1 }}>
                                        Total cost
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip variant='outlined' label="R 68.60 for Time and attendance" /> + <Chip variant='outlined' label="R 1.00 for Notification subscriptions" /> = <Chip color='primary' label="R 67.60 per month" />
                                    </Typography>
                                </Container>
                            </CardContent>
                        </Card>
                        <Card sx={{ my: 3 }}>
                            <CardContent>
                                <Typography color="primary" align="left" variant="h2">
                                    Scenario 2
                                </Typography>
                                <Typography color="primary" align="left" variant="body1" sx={{ my: 2 }}>
                                    You have 150 labourers that clock in and out with the mobile application every day. You also create "approved overtime" records for 15 people for the one day that they worked overtime during the month, and you log 25 leave records.
                                    You want to stay on top of your attendance, and thus configured to receive a daily absentee report during the week in addition to a weekly attendance report.
                                    <br /><br />
                                    You also harvest with the system. You have a harvest team of 40 people that harvest every day of the week. The workers each records an average of 35 units a day.
                                    You also want to stay on top of your harvest records, and subscribe to "Outstanding harvest records" every weekday, as well as a daily and weekly harvest report.
                                    <br /><br />
                                    In addition to this, you have a team of 2 scouts that log soil moisture. They average 50 records per day each for the 20 work days of the month.
                                </Typography>
                                <Container sx={{ my: 2 }}>
                                    <Typography color="primary" align="left" variant="h4" sx={{ mb: 1 }}>
                                        Time and attendance
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="150 labourers" /> x <Chip label="20 work days" /> x <Chip label="2 clock records per day" /> = <Chip label="6 000 records" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="15 labourers" /> x <Chip label="1 overtime record" /> = <Chip label="15 records" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="25 leave records" /> = <Chip label="25 records" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="6 040 records" /> - <Chip label="300 free tier records per month" /> x <Chip label="R 0.04 per record" /> = <Chip variant='outlined' label="R 229.60 per month" />
                                    </Typography>
                                </Container>
                                <Container sx={{ my: 2 }}>
                                    <Typography color="primary" align="left" variant="h4" sx={{ mb: 1 }}>
                                        Harvesting
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="40 labourers" /> x <Chip label="35 units per day" /> x <Chip label="20 work days" /> = <Chip label="28 000 records" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="28 000 records" /> - <Chip label="200 free tier records per month" /> x <Chip label="R 0.10 per record" /> = <Chip variant='outlined' label="R 2 780.00 per month" />
                                    </Typography>
                                </Container>
                                <Container sx={{ my: 2 }}>
                                    <Typography color="primary" align="left" variant="h4" sx={{ mb: 1 }}>
                                        Activities/Instructions (Scouting)
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="2 labourers" /> x <Chip label="50 scout records a day" /> x <Chip label="20 week days" /> = <Chip label="2 000 records" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="2 000 records" /> - <Chip label="200 free tier records per month" /> x <Chip label="R 0.08 per record" /> = <Chip variant='outlined' label="R 144.00 per month" />
                                    </Typography>
                                </Container>
                                <Container sx={{ my: 2 }}>
                                    <Typography color="primary" align="left" variant="h4" sx={{ mb: 1 }}>
                                        Notification subscriptions
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="3 Daily reports" /> x <Chip label="1 recipient" /> x <Chip label="20 week days" /> = <Chip label="60 emails" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="2 Weekly reports" /> x <Chip label="2 recipients" /> x <Chip label="4 weeks" /> = <Chip label="16 emails" />
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip label="76 emails" /> - <Chip label="20 free tier emails per month" /> x <Chip label="R 0.25 per email" /> = <Chip variant='outlined' label="R 14.00 per month" />
                                    </Typography>
                                </Container>
                                <Container sx={{ my: 2 }}>
                                    <Typography color="primary" align="left" variant="h4" sx={{ mb: 1 }}>
                                        Total cost
                                    </Typography>
                                    <Typography color="primary" align="left" variant="body2" sx={{ my: 1 }}>
                                        <Chip variant='outlined' label="R 229.60 for Time and attendance" /> + <Chip variant='outlined' label="R 2 780.00 for Harvesting" /> + <Chip variant='outlined' label="R 144.00 for Activities/Instructions" /> + <Chip variant='outlined' label="R 14.00 for Notification subscriptions" /> = <Chip color='primary' label="R 3 167.60 per month" />
                                    </Typography>
                                </Container>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default PricingPage;