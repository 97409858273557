import * as React from 'react';
import { Grid, Container, Box, Typography, Button } from '@mui/material';
import { Email } from '@mui/icons-material';

function GettingStarted(props) {
    return (
        <Box
            sx={{
                backgroundColor: 'rgba(255,255,255,0.75)',
                position: 'relative',
                display: 'flex',
            }}
        >
            <Container
                sx={{
                    pt: 10,
                    pb: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography color="primary" align="center" variant="h1" marked="center" sx={{ mb: 1 }}>
                            Getting started
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" align="center" variant="body2" marked="center" sx={{ mb: 1 }}>
                            Getting started with our agriculture management software is easy!
                            <br />
                            Simply request an invitation to join our platform and one of our team members will be in touch shortly.
                            <br />
                            During the setup process, we'll work closely with you to understand your specific needs and make sure our software is configured to meet those needs. Our user-friendly interface makes it easy for you to get up and running quickly, and our commitment to ongoing support means you'll always have the resources you need to make the most of our platform.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color="primary" align="center" variant="h5" marked="center" sx={{ mb: 1 }}>
                            Request your invitation today and see the difference our innovative solutions can make for your operation.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            component="a"
                            to='#'
                            startIcon={<Email />}
                            onClick={(e) => {
                                window.location.href = `mailto:info@rootsolutions.tech`;
                                e.preventDefault();
                            }}
                            sx={{ minWidth: 200 }}
                        >
                            Email us
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default GettingStarted;