import * as React from 'react';
import { Grid, Container, Box, Typography, Card, Button, CardContent } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

const CustomRouterLink = React.forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <NavLink {...props} />
    </div>
));

function Pricing(props) {
    return (
        <Box
            sx={{
                bgcolor: 'white'
            }}
        >
            <Container
                sx={{
                    pt: 10,
                    pb: 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography color="primary" align="left" variant="h1" marked="center">
                            Use Based Pricing
                        </Typography>
                        <Typography color="primary" align="left" variant="body1" marked="center">
                            Only pay for what you use.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4} sx={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}>
                            <Grid item xs={12} sm={3}>
                                <Card sx={{ height: "100%" }}>
                                    <CardContent>
                                        <Typography color="primary" align="center" variant="h6" marked="center" sx={{ mt: 2, mb: 3 }}>
                                            Use based pricing
                                        </Typography>
                                        <Typography color="primary" align="center" variant="body2" marked="center">
                                            Our agriculture management software is priced based on usage, making it accessible to businesses of all sizes. Our pricing model allows you to only pay for the features and capabilities you need, while still having access to our full suite of tools.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Card sx={{ height: "100%" }}>
                                    <CardContent>
                                        <Typography color="primary" align="center" variant="h6" marked="center" sx={{ mt: 2, mb: 3 }}>
                                            Free tier
                                        </Typography>
                                        <Typography color="primary" align="center" variant="body2" marked="center">
                                            To accommodate different budgets and use cases, we also offer a free tier that provides ALL functionality, allowing you to get started and see the value of our platform. With our commitment to affordability, our software is the right choice for any agriculture business looking to streamline operations and drive success.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Card sx={{ height: "100%" }}>
                                    <CardContent>
                                        <Typography color="primary" align="center" variant="h6" marked="center" sx={{ mt: 2, mb: 3 }}>
                                            Unlimited growth
                                        </Typography>
                                        <Typography color="primary" align="center" variant="body2" marked="center">
                                            Let the software grow with your business needs and budget. Have unlimited hectares, members/users & storage.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Card sx={{ height: "100%" }}>
                                    <CardContent>
                                        <Typography color="primary" align="center" variant="h6" marked="center" sx={{ mt: 2, mb: 3 }}>
                                            Self service
                                        </Typography>
                                        <Typography color="primary" align="center" variant="body2" marked="center">
                                            Designed to be user-friendly and intuitive, so you can get started right away without any prior technical experience. Additionally, we provide easy to use training documentation, so you can quickly become proficient in using our platform.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            color="secondary"
                            variant="contained"
                            component={CustomRouterLink}
                            to={"/pricing"}
                            endIcon={<OpenInNew />}
                        >
                            Pricing details
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Pricing;